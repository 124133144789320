<template>
  <div>
    <div
      v-if="!saveAsImg"
      class="p-relative bg-image ratio"
      @click="$emit('click', $event)"
      @blur="$emit('blur', $event)"
      @focus="$emit('focus', $event)"
      :style="{
        backgroundImage: 'url(' + src + ')',
        paddingBottom: ratio ? padding + '%' : '',
        backgroundSize: contain ? 'contain' : 'cover',
        backgroundRepeat: 'no-repeat'
      }"
    >
      <div class="p-absolute fill-parent content-center" v-if="!loaded || !src">
        <span class="sticker bg-light" :class="{ 'sticker-sm': small }"
          ><i class="i-image"></i
        ></span>
      </div>
    </div>
    <div v-else>
      <img :src="src" alt="" :style="{ width: '100%' }" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    src: String,
    small: Boolean,
    contain: Boolean,
    ratio: String,
    saveAsImg: Boolean
  },
  computed: {
    padding() {
      let r = this.ratio ? this.ratio.toString().split("x") : [4, 3];

      return (r[1] / r[0]) * 100;
    }
  },
  data() {
    return {
      loaded: false
    };
  },
  watch: {
    src: function(newVal) {
      if (!newVal) return;

      let img = new Image();
      img.src = newVal;

      this.loaded = false;

      img.onload = () => {
        this.loaded = true;
        this.$emit("loaded", newVal);
      };
    }
  },
  mounted() {
    if (this.src === undefined) return;

    let img = new Image();
    img.src = this.src;

    img.onload = () => {
      this.loaded = true;
      this.$emit("loaded", this.src);
    };
  }
};
</script>
