var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.saveAsImg
      ? _c(
          "div",
          {
            staticClass: "p-relative bg-image ratio",
            style: {
              backgroundImage: "url(" + _vm.src + ")",
              paddingBottom: _vm.ratio ? _vm.padding + "%" : "",
              backgroundSize: _vm.contain ? "contain" : "cover",
              backgroundRepeat: "no-repeat"
            },
            on: {
              click: function($event) {
                return _vm.$emit("click", $event)
              },
              blur: function($event) {
                return _vm.$emit("blur", $event)
              },
              focus: function($event) {
                return _vm.$emit("focus", $event)
              }
            }
          },
          [
            !_vm.loaded || !_vm.src
              ? _c(
                  "div",
                  { staticClass: "p-absolute fill-parent content-center" },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "sticker bg-light",
                        class: { "sticker-sm": _vm.small }
                      },
                      [_c("i", { staticClass: "i-image" })]
                    )
                  ]
                )
              : _vm._e()
          ]
        )
      : _c("div", [
          _c("img", {
            style: { width: "100%" },
            attrs: { src: _vm.src, alt: "" }
          })
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }